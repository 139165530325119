/*
  定义后台接口地址常量，方便统一管理
 */
export const MANAGEMENT_LOGIN = '/api/multi_platform_manage_admin_bff/v1/login'
// export const MANAGEMENT_LOGOUT = '/api/game_store_admin/v1/rbac/logout'
// export const MANAGEMENT_USER = '/api/game_store_admin/v1/rbac/user'
export const MANAGEMENT_MENUS = '/api/multi_platform_manage_admin_bff/v1/user/menus' // '/api/manage_platform/v1/menus'
// export const MANAGEMENT_AUTH = '/api/game_store_admin/v1/rbac/auth/:token'

//接口
export const UPLOAD_FILE = '/api/multi_platform_manage_admin_bff/v1/game/upload-file'
export const UPLOAD_POLICY_TOKEN = '/api/multi_platform_manage_admin_bff/v1/game/upload-policy-token'
export const GAME_OPTIONS = '/api/multi_platform_manage_admin_bff/v1/game/options'
export const GAME_LIST = '/api/multi_platform_manage_admin_bff/v1/game/list'
export const GAME_DELETE = '/api/multi_platform_manage_admin_bff/v1/game/delete'
export const GAME_DETAIL = '/api/multi_platform_manage_admin_bff/v1/game/detail'
export const GAME_DETAIL_BY_APPID = '/api/multi_platform_manage_admin_bff/v1/game/detail_by_appid'
export const GAME_UPDATE = '/api/multi_platform_manage_admin_bff/v1/game/update'
export const RELATE_GAMES = '/api/multi_platform_manage_admin_bff/v1/game/relate-games'
export const SYNC_GAME = '/api/multi_platform_manage_admin_bff/v1/game/sync'

//文件上传接口
export const FILE_UPLOAD = '/api/multi_platform_manage_admin_bff/v1/game/upload-file'

export const TREASURE_BAG_LIST = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/page_list'

export const ADD_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/add'
export const UPDATE_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/update'

export const DELETE_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/delete'

export const SORT_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/rearrange'

// Steam宝盒 广告位接口
export const ADVERTISING_LIST = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/page_list'

export const ADD_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/add'
export const UPDATE_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/update'
export const DELETE_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/delete'
export const SORT_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/rearrange'
